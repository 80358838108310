import React, { useEffect, useState } from "react";
import "./meal.css";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance, { axiosTokenInstance } from "../../axiosConfig/axios";
import { Button } from "react-bootstrap";
import { Minus, Plus } from "lucide-react";
import ProductDetails from "./ProductDetails";
import Buy from "../../assets/images/Buy.svg";

const Meal = () => {
  const navigate = useNavigate();

  const [Product, setProduct] = useState([]);
  const [Sizes, setSizes] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [productPrice, setProductPrice] = useState(0);
  const [notes, setNotes] = useState("");

  let [count, setCount] = useState(1);

  const params = useParams();

  const restaurantID = localStorage.getItem("restaurant");

  const getProductDetails = async () => {
    const { data } = await axiosInstance.get(
      `user/menu/${restaurantID}/singleItem/${params.id}`
    );

    console.log(data.data);

    setProduct(data.data);
    setSizes(data.data.sizes);
    setProductOptions(data.data.product_options);

    if (data.data.sizes.length > 0) {
      setSelectedSize(data.data.sizes[0].id);
      setProductPrice(data.data.sizes[0].price_with_tax);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  const handleCheckboxChange = (e, optionId, optionPrice) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add option to the list and update price
      setOptions((prevOptions) => [...prevOptions, optionId]);
      setProductPrice(
        (prevPrice) => parseFloat(prevPrice) + parseFloat(optionPrice)
      );
    } else {
      // Remove option from the list and update price
      setOptions((prevOptions) =>
        prevOptions.filter((option) => option !== optionId)
      );
      setProductPrice(
        (prevPrice) => parseFloat(prevPrice) - parseFloat(optionPrice)
      );
    }
  };

  const handleSizeChange = (sizeId, sizePrice) => {
    setSelectedSize(sizeId);
    // Reset price to the base size price and reapply addons
    setProductPrice(() => {
      const addonsTotal = options.reduce((total, optionId) => {
        const addon = productOptions.find((opt) => opt.id === optionId);
        return addon ? total + parseFloat(addon.price_with_tax) : total;
      }, 0);
      return parseFloat(sizePrice) + addonsTotal; // Base price + addons total
    });
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const requestProductBody = {
    productId: Product.id,
    qty: count,
    size: selectedSize,
    options: options,
    notes: notes,
    restaurant_id: restaurantID,
  };

  const token = localStorage.getItem("token");

  const addToCart = async () => {
    try {
      const res = await axiosTokenInstance.post(
        "user/cart",
        requestProductBody
      );

      if (res.data.status === 200) {
        toast.success("Added successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });

        setTimeout(() => {
          navigate("/menu");
        }, 5000);
      } else if (res.data.status === 422) {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      if (error.response) {
        toast.error("Server error: " + error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      } else if (error.request) {
        toast.error("No response from the server.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      } else {
        toast.error("An error occurred: " + error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <>
      <ProductDetails
        Product={Product}
        Sizes={Sizes}
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        productPrice={productPrice}
        setProductPrice={setProductPrice}
        productOptions={productOptions}
        handleCheckboxChange={handleCheckboxChange}
        notes={notes}
        setNotes={setNotes}
        handleSizeChange={handleSizeChange}
      />

      <div className="price-of-product-details-container">
        <div className="price-of-product-details">
          <div className="total-product-price">
            <div>
              <p>السعر الاجمالى</p>
              <p>{productPrice * count} ر.س</p>
            </div>
            <div className="product-group-btns">
              <button className="plusBtn" onClick={incrementCount}>
                <Plus size="16" />
              </button>
              <p>{count}</p>
              <button className="minusBtn" onClick={decrementCount}>
                <Minus size="16" />
              </button>
            </div>
          </div>

          <div className="cart-footer">
            <img className="Buy" src={Buy} alt="Buy" />{" "}
            <Button
              onClick={(event) => {
                event.preventDefault();
                if (token) {
                  addToCart();
                } else {
                  navigate("/login");
                }
              }}
            >
              أضف الى السلة ر.س
            </Button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Meal;
