import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Downloading from "../pages/downloading/downloading";
import Meal from "../pages/meal/meal";
import Card from "../pages/cart/cart";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Otp from "../pages/Otp/Otp";
import RestaurantMenu from "../pages/RestaurantMenu/RestaurantMenu";
import Order from "../pages/order/Order";
import Review from "../pages/Review/Review";
import { ThanksONReview } from "../pages/Review/Thanks-onReview";
import SplitOrder from "../pages/Payment/split-order";
import WaysOfPayment from "../pages/Payment/ways-of-payment";
import AllPayment from "../pages/Payment/all-payment";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      {
        path: "webfeastap",
        element: <Downloading />,
      },
      {
        path: "menu",
        element: <RestaurantMenu />,
      },
      {
        path: "meal/:id",
        element: <Meal />,
      },
      {
        path: "cart",
        element: <Card />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "otp",
        element: <Otp />,
      },

      {
        path: "review",
        element: <Review />,
      },
      {
        path: "thanksOnReview",
        element: <ThanksONReview />,
      },
      {
        path: "waysOfPayment",
        element: <WaysOfPayment />,
      },
      {
        path: "split-order",
        element: <SplitOrder />,
      },
      {
        path: "all-payment",
        element: <AllPayment />,
      },
    ],
  },
]);
