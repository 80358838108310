import React from "react";
import { Button } from "react-bootstrap";
import orderImg from "../../assets/images/order-done.svg";
import { useNavigate } from "react-router-dom";
export const Popup = ({ closePopup }) => {
  const navigate = useNavigate();
  return (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content">
        <img src={orderImg} alt="order-done" />
        <h3>تم تأكيد طلبك بنجاح</h3>

        <Button onClick={() => navigate("/order")}>الذهاب الى طلباتى</Button>
      </div>
    </div>
  );
};
