import recepit from "../../assets/images/product-details/receipt-edit.svg";
import circleAdd from "../../assets/images/product-details/Group 1000001487.svg";
import { Form } from "react-bootstrap";
import "./note.css";

const Note = ({ notes, setNotes }) => {
  return (
    <>
      {/* Start Note of products details */}
      <div className="notes">
        <div className="icon-note-container">
          <div className="icon-container">
            <img src={recepit} alt="recepit" />
            <h3>اضف ملاحظاتك على الطلب</h3>
          </div>
          <img src={circleAdd} alt="circleAdd" className="circleAdd" />
        </div>
        <Form.FloatingLabel
          controlId="floatingTextarea2"
          className="FloatingLabel"
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          label="أضف ملاحظاتك على الاوردر"
        >
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: "100px", marginBottom: "25px" }}
          />
        </Form.FloatingLabel>
      </div>
      {/* End Note of products details */}
    </>
  );
};
export default Note;
