import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { axiosTokenInstance } from "../../axiosConfig/axios";
import SplitPaymentOrder from "./split-payment-order";
import "./payment.css";

const SplitOrder = () => {
  const navigate = useNavigate();
  const [orderProductDetails, setOrderProductDetails] = useState({});

  const [options, setOptions] = useState([]);

  const handleCheckboxChange = (e, cart_item_id, quantity) => {
    const isChecked = e.target.checked;
    console.log(cart_item_id, quantity);

    if (isChecked) {
      setOptions((prevOptions) => [
        ...prevOptions,
        { itemId: cart_item_id, qty: quantity },
      ]);
    } else {
      setOptions((prevOptions) =>
        prevOptions.filter((option) => option !== cart_item_id)
      );
    }
  };
  console.log(options);
  const order_id = localStorage.getItem("order-id");

  // get remain products details
  const getRemainProductsDetails = () => {
    axiosTokenInstance.get(`user/order/${order_id}`).then((res) => {
      console.log(res.data);
      setOrderProductDetails(res.data.data);
    });
  };

  useEffect(() => {
    getRemainProductsDetails();
  }, []);

  // pay pert of order
  const payPartOfOrder = () => {
    axiosTokenInstance
      .post(`/user/order/simiPay/${order_id}`, { items: options })
      .then((res) => {
        console.log(res.data);
        if (res.data?.redirect_url) {
          window.location.href = res.data.redirect_url;
        } else {
          console.error("Redirect URL not found!");
        }
      });
  };

  return (
    <div className="split-my-orders">
      <h2>دفع جزء من الطلب</h2>

      <div className="split-total-price">
        <h4>اجمالى قيمة الطلب</h4>
        <p>
          {orderProductDetails?.remainSummary?.price_with_tax_and_discount} ر.س
        </p>
      </div>

      <div className="order-split-notPaid">
        <h3>اختر العنصر المراد دفعه</h3>
        <hr />
        <div className="order-details">
          {orderProductDetails?.remainProducts?.length > 0 ? (
            orderProductDetails.remainProducts.map((product, index) => (
              <>
                {" "}
                <SplitPaymentOrder
                  key={index}
                  product={product}
                  handleCheckboxChange={handleCheckboxChange}
                />
                <hr />
              </>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* <div className="split-total-price">
        <h4>الاجمالى الصافى</h4>
        <p>{orderProductDetails?.remainSummary?.total} ر.س</p>
      </div>

      <div className="split-total-price">
        <h4>الاجمالى الصافى</h4>
        <p>{orderProductDetails?.remainSummary?.total} ر.س</p>
      </div> */}

      <button onClick={() => payPartOfOrder()}>هدفع</button>
    </div>
  );
};

export default SplitOrder;
