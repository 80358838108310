import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig/axios";

import Slider from "../../components/swiper/slider";
import RestaurantHeader from "./RestaurantHeader";
import TabBar from "./tabbar";
import MenuProductList from "./MenuProductList";
import OfferList from "./OfferList";
import RestaurantInfo from "./RestaurantInfo";
import Reviews from "./Reviews";
import "./menu.css";
import ShowCart from "../../components/showCart/showCart";

const RestaurantMenu = () => {
  const [resturantSliderImages, setResturantSliderImages] = useState([]);
  const [resturantReviews, setResturantReviews] = useState({});
  const [resturantInfoData, setResturantInfoData] = useState({
    name: "",
    about: "",
    location: { address: "", latitude: "", longitude: "" },
    openTime: "",
    closeTime: "",
  });
  const [resturantInfo, setResturantInfo] = useState({
    name: "",
    address: "",
    distance: "",
    rating: 0,
    logo: "",
    openTime: "",
    closeTime: "",
    m_categories: "",
    menuCategory: [],
    menuProducts: [],
    offers: [],
  });

  const [activeTab, setActiveTab] = useState("المنيو");

  const restaurantId = localStorage.getItem("restaurant");

  const getData = async () => {
    const { data } = await axiosInstance.get(`user/menu/${restaurantId}`);
    console.log(data.data);

    setResturantSliderImages(data?.data?.info?.resturantImages);
    setResturantInfo({
      name: data.data.info.name,
      address: data.data.info.address,
      distance: data.data.info.distance,
      rating: data.data.info.rating,
      logo: data.data.info.Logo,
      openTime: data.data.info.openTime,
      closeTime: data.data.info.closeTime,
      m_categories: data.data.info.m_categories,
      menuCategory: data.data.menuCategory,
      menuProducts: data.data.menuProducts,
      offers: data.data.offers,
    });
  };

  const getReviews = async () => {
    const { data } = await axiosInstance.get(`user/reviews/${restaurantId}`);

    setResturantReviews(data.data);
  };

  const getInfoData = async () => {
    const { data } = await axiosInstance.get(`user/menu/info/${restaurantId}`);

    setResturantInfoData({
      name: data.data.name,
      about: data.data.about,
      location: {
        address: data.data.location.address,
        latitude: data.data.location.latitude,
        longitude: data.data.location.longitude,
      },
      openTime: data.data.openTime,
      closeTime: data.data.closeTime,
    });
  };

  useEffect(() => {
    getData();
    getReviews();
    getInfoData();
  }, []);

  const tabs = ["المنيو", "عروض", "مراجعات"];
  const categories = [
    ...(resturantInfo.menuCategory?.length > 0
      ? resturantInfo.menuCategory.map((category) => category.name)
      : []),
  ];

  // Render content based on activeTab
  const renderTabContent = () => {
    switch (activeTab) {
      case "المنيو":
        return (
          <>
            <TabBar
              tabs={categories}
              nameOfClass="content-tap-button"
              mainClass="tab-bar2"
            />
            <MenuProductList
              filteredProducts={resturantInfo?.menuProducts}
              categories={categories}
            />
          </>
        );
      case "عروض":
        return <OfferList offers={resturantInfo.offers} />;

      case "مراجعات":
        return <Reviews reviews={resturantReviews} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Slider images={resturantSliderImages} />
      <div style={{ padding: "0 20px" }}>
        <RestaurantHeader resturantInfo={resturantInfo} />
      </div>

      <div className="menu-content">
        <TabBar
          tabs={tabs}
          nameOfClass="main-tap-button"
          mainClass="tab-bar1"
          onTabClick={setActiveTab} // Pass a function to handle tab changes
        />

        {renderTabContent()}
      </div>
      <div style={{ position: "relative" }}>
        <ShowCart />
      </div>
    </>
  );
};

export default RestaurantMenu;
