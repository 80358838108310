import { Button } from "react-bootstrap";
import emotionIMg from "../../assets/images/emotion-thanks.svg";
import { useNavigate } from "react-router-dom";
export const ThanksONReview = () => {
  const navigate = useNavigate();
  return (
    <div className="thanks-overlay">
      <div className="thanks-content">
        <img src={emotionIMg} alt="emotion-thanks" />
        <h3>نشكرك على تقيمك</h3>

        <Button onClick={() => navigate("/menu")}>الرئيسية</Button>
      </div>
    </div>
  );
};
