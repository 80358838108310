const OrderDetails = ({ product }) => {
  return (
    <div className="order">
      <h4>
        <span> {product.quantity}</span>
        {product.name}
      </h4>
      <p>{product.summary.price_with_tax_and_discount} ر.س</p>
    </div>
  );
};

export default OrderDetails;
