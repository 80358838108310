import "../showCart/sowCart.css";
import { Link } from "react-router-dom";

const ShowCart = () => {
  const Total_price_with_taxs = localStorage.getItem("Total_price_with_taxs");
  const cartProductsLength = localStorage.getItem("cartProductsLength");
  return (
    <div className="main-content-cart">
      <div className="showCart">
        <Link to="/cart" className="link">
          <div className="main-content">
            <div className="content">
              <p>
                <span>{cartProductsLength ? cartProductsLength : 0}</span>{" "}
                مشاهدة السلة
              </p>
            </div>
            <p className="price">
              {cartProductsLength ? Total_price_with_taxs : 0} ر.س
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ShowCart;
