import greenTrue from "../../assets/images/green-true.svg";
import greyTrue from "../../assets/images/grey-true.svg";

const OrderStatus = ({ currentStatus, paidStatus }) => {
  // Define the status steps
  const steps = [
    {
      id: 1,
      name: "الطلب معلق",
      description: "في انتظار قبول الشيف للطلب لبدء التجهيز",
    },
    {
      id: 2,
      name: "جاري تحضير الطلب",
      description: "تم قبول طلبك من الشيف و جاري التحضير",
    },
    {
      id: 3,
      name: "طلبك على طاولتك",
      description: "طلبك على طاولتك و نتمنى ان تشاركنا تجربتك حول المطعم",
    },
    { id: 4, name: "تم الدفع", description: "تم دفع الحساب بنجاح" },
  ];

  return (
    <>
      <div className="order-status-header">حالة الطلب</div>
      <div className="order-status-container">
        {steps.map((step, index) => (
          <div key={step.id} className="order-step">
            <div className="step-icon">
              {step.id < 4 && step.id <= currentStatus?.id ? (
                <img src={greenTrue} alt="active-true" />
              ) : step.id === 4 && paidStatus === "FULL_PAID" ? (
                <img src={greenTrue} alt="active-true" />
              ) : (
                <img src={greyTrue} alt="not-active-true" />
              )}
              {index !== steps.length - 1 && (
                <div className="dashed-line"></div>
              )}
            </div>

            <div className="step-content">
              <h3>{step.name}</h3>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OrderStatus;
