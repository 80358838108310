import { Minus, Plus } from "lucide-react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
const SplitPaymentOrder = ({ product, handleCheckboxChange }) => {
  const [quantity, setQuantity] = useState(product.remainQuantity); // Local state for quantity

  const handleDecrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrement = () => {
    if (quantity >= product.remainQuantity) {
      toast.error("لايمكن زيادة الكمية أكثر من الموجودة في طلبك", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    } else {
      setQuantity(quantity + 1);
    }
  };

  return (
    <>
      {" "}
      <div className="split-order-item">
        <div className="split-order-details">
          <img src={product.image} alt={product.name} />
          <div className="content">
            <h4>{product.name}</h4>
            <h5>{product.size.name}</h5>
            <p>{product.summary.price_with_tax_and_discount} ر.س</p>
            <div className="btns-container">
              <Button onClick={() => handleIncrement()}>
                <Plus size={"15"} />
              </Button>
              <div className="quantity">{quantity}</div>
              <Button>
                <Minus
                  size={"15"}
                  onClick={handleDecrementQuantity}
                  disabled={quantity <= 1}
                />
              </Button>
            </div>
          </div>
        </div>

        <Form.Check
          className="custom-checkbox"
          type="checkbox"
          reverse
          onChange={(e) =>
            handleCheckboxChange(e, product.size.cart_item_id, quantity)
          }
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default SplitPaymentOrder;
