import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://dev.feastap.com/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "ar",
  },
  withCredentials: true,
});

const axiosTokenInstance = axios.create({
  baseURL: "https://dev.feastap.com/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "ar",
  },
  withCredentials: true,
});

//request
axiosTokenInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//response
axiosTokenInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { axiosTokenInstance };
