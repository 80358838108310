import React from "react";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";
import discount from "../../assets/images/discount 1.svg";

const OfferList = ({ offers }) => {
  return (
    <div className="container-product">
      {offers && offers.length > 0 ? (
        offers.map((offer) => (
          <div key={offer.id}>
            <Link to={`/meal/${offer.id}`} style={{ textDecoration: "none" }}>
              <div
                className={offer.is_visible ? `product` : `product-notVisible`} // Conditional class for visibility
              >
                <div className="container-content">
                  <img src={offer.image} alt={offer.name} />
                  <div className="content">
                    <div className="text">
                      <h3>{offer.name}</h3>
                      <span
                        className={
                          offer.is_visible ? `is-not-visible` : `is-visible`
                        }
                      >
                        {" "}
                        (غير متاح)
                      </span>
                    </div>
                    <div>
                      {offer.price_with_tax <
                        offer.price_before_discount_with_tax &&
                      offer.price_with_tax > 0 ? (
                        <>
                          {/* Discounted Price */}
                          <p
                            style={
                              offer.is_visible
                                ? {
                                    color: "red",
                                    textDecoration: "line-through",
                                  }
                                : {
                                    color: "gray",
                                    textDecoration: "line-through",
                                  }
                            }
                          >
                            {offer.price_before_discount_with_tax} ر.س
                          </p>
                          <p className="price">
                            <span>{offer.price_with_tax}</span> ر.س
                          </p>
                        </>
                      ) : (
                        <p className="price">
                          <span>{offer.price_with_tax}</span> ر.س
                        </p>
                      )}
                    </div>

                    {offer.badge !== null ? (
                      <p
                        className="product-badge"
                        style={
                          offer.is_visible
                            ? {
                                backgroundColor: offer.badge.background_color,
                                color: "white",
                              }
                            : {
                                backgroundColor: "gray",
                                color: "black",
                              }
                        }
                      >
                        {offer.badge.name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    offer.is_visible ? `product-btn` : `is-not-visible`
                  } // Disable button for invisible offers
                >
                  <button disabled={!offer.is_visible}>
                    <Plus size={15} />
                  </button>
                </div>
              </div>
            </Link>
          </div>
        ))
      ) : (
        <div className="no-data">
          <img src={discount} alt="discount" />
          <h6>لايوجد عروض</h6>
        </div>
      )}
    </div>
  );
};

export default OfferList;
